import axios from 'axios';
import { publicCommonClient } from './index';
import jwtService from '../jwtService';
import * as _ from 'lodash';

const apiUrls = [process.env.REACT_APP_COMMON_SERVICE_API_URL];

export const requestInterceptor = async (config) => {
  if (!jwtService.hasAccessToken()) {
    throw new axios.Cancel('Operation canceled');
  }

  const accessToken = jwtService.getAccessToken();

  config.headers.common['Authorization'] = `Bearer ${accessToken}`;
  return config;
};

export const responseInterceptor = (client) => async (error) => {
  const unauthorizedError = error.response && error.response.status === 401;
  const isRequestToApi = error.response && _.some(apiUrls, url => _.includes(error.response.request.responseURL, url));

  if (unauthorizedError && isRequestToApi) {
    const { signInToken } = await setNewTokenPair();

    if (signInToken) {
      error.config.headers['Authorization'] = `Bearer ${signInToken}`;

      return client(error.config);
    } else {
      jwtService.removeAccessToken();
      jwtService.removeRefreshToken();
    }
  }

  throw error;
};

async function setNewTokenPair() {
  try {
    if (!jwtService.hasRefreshToken()) {
      return {};
    }

    const oldRefreshToken = jwtService.getRefreshToken();
    const { data: { signInToken, refreshToken } } = await publicCommonClient.post('/api/auth/refreshToken', { token: oldRefreshToken });

    jwtService.setAccessToken(signInToken);
    jwtService.setRefreshToken(refreshToken);
    return { signInToken, refreshToken };
  } catch (e) {
    return {};
  }
}

export default {
  requestInterceptor,
  responseInterceptor
};
