import React, { useState } from 'react';
import { Tabs } from 'antd';
import FormAddress from '../forms/FormAddress';
import FormInvoices from '../forms/FormInvoices';
import FormSubscription from '../forms/FormSubscription';
import FormSMS from '../forms/FormSMS';
import FormDashboardMain from '../forms/FormDashboardMain';
import FormUsers from '../forms/FormUsers';
import FormNotes from '../forms/FormNotes';
import UserFile from '../forms/UserFile';
import UserList from '../forms/UserList';

const { TabPane } = Tabs;

const HeaderBarAccounts: React.FC = () => {
  // State to keep track of the currently active tab
  const [activeTab, setActiveTab] = useState<string>('1');

  // Callback function to update the active tab
  const callback = (key: string) => {
    console.log(key);
    setActiveTab(key); // Update the active tab state
  };


  // Function components for rendering forms
  const renderFormAddress = () => <FormAddress></FormAddress>;
  const renderFormSubscription = () => <FormSubscription></FormSubscription>;
  const renderFormUsers = () => <FormUsers></FormUsers>;
  const renderFormNotes = () => <FormNotes></FormNotes>;
  const renderFormSMS = () => <FormSMS></FormSMS>;
  const renderFormInvoices = () => <FormInvoices></FormInvoices>;
 

  return (
    <>
      <div className="div" style={{ marginLeft: '10px' }}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Address" key="1">
            <div className="div-3">
              <div className="div-4">
                {activeTab === '1' && renderFormAddress()}
              </div>
              <div className="div-5" />
            </div>
          </TabPane>
          <TabPane tab="Subscription" key="2">
            {activeTab === '2' && renderFormSubscription()}
          </TabPane>
          <TabPane tab="Invoices" key="3">
            {activeTab === '3' && renderFormInvoices()}
          </TabPane>
          <TabPane tab="SMS" key="4">
            {activeTab === '4' && renderFormSMS()}
          </TabPane>
          <TabPane tab="Users" key="5">
            {activeTab === '5' && renderFormUsers()}
          </TabPane>
          <TabPane tab="Notes" key="6">
            {activeTab === '6' && renderFormNotes()}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default HeaderBarAccounts;
