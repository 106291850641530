import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, List, Switch, Space, Input, Form, Pagination, FormInstance } from 'antd';


import _ from 'lodash';

interface NewsItem {
  menuTitle: string;
  title: string;
  text: string;
  published: boolean;
  news_id?: string;
  date: string;
}

const NewsPage: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [allNewsList, setAllNewsList] = useState<NewsItem[]>([]);
  const [currentNews, setCurrentNews] = useState<NewsItem | null>(null);
  const formRef = useRef<FormInstance>(null);

  // Simulate fetching news
  useEffect(() => {
    // Replace this with your actual fetch call
    const fetchNews = async () => {
      const fetchedNews: NewsItem[] = []; // Your fetch logic here
      setAllNewsList(fetchedNews);
    };

    fetchNews();
  }, []);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const showUpdateModal = (item: NewsItem) => {
    setCurrentNews(item);
    setUpdateModalVisible(true);
  };

  const hideUpdateModal = () => setUpdateModalVisible(false);

  // Simulated submit operation
  const submitNews = async (values: any) => {
    // Implement your submission logic here
    console.log('Submitting news:', values);
    hideModal();
  };

  // Simulated update operation
  const updateNewsItem = async (values: any) => {
    // Implement your update logic here, using currentNews.news_id and values
    console.log('Updating news:', values);
    hideUpdateModal();
  };

  // Simulated delete operation
  const deleteNewsItem = async () => {
    if (currentNews?.news_id) {
      // Implement your delete logic here, using currentNews.news_id
      console.log('Deleting news with ID:', currentNews.news_id);
    }
    hideUpdateModal();
  };

  return (
    <div className="newsPage">
      <List
  itemLayout="horizontal"
  dataSource={_.sortBy(allNewsList, ['date'])}
  renderItem={(item: NewsItem) => ( // Explicitly type the `item` parameter here
    <List.Item>
      <List.Item.Meta title={`${item.menuTitle} - ${item.title}`} description={item.text} />
      <Button type="primary" onClick={() => showUpdateModal(item)}>Edit</Button>
    </List.Item>
  )}
/>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Button type="primary" onClick={showModal}>New</Button>
        <Pagination />
      </Space>

      <Modal
        visible={modalVisible}
        title="New News Item"
        onCancel={hideModal}
        footer={null}
      >
        <Form name="new_news_form" onFinish={submitNews}>
          {/* Form fields and Submit button */}
        </Form>
      </Modal>

      <Modal
        visible={updateModalVisible}
        title="Update News Item"
        onCancel={hideUpdateModal}
        footer={null}
      >
        <Form
          ref={formRef}
          name="update_news_form"
          onFinish={updateNewsItem}
          initialValues={{
            menuTitle: currentNews?.menuTitle,
            title: currentNews?.title,
            text: currentNews?.text,
            published: currentNews?.published,
          }}
        >
          {/* Form fields, Update and Delete buttons */}
        </Form>
      </Modal>
    </div>
  );
};

export default NewsPage;
