import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import api from '../../interface/api/api';

// Assuming the structure of a News item
interface News {
  id: string;
  title: string;
  content: string;
  publishedAt: string;
  // Define other fields as needed...
}

interface NewsState {
  data: News[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialNewsState: NewsState = {
  data: [],
  status: 'idle',
};

// Async thunk action for fetching news
export const fetchNews = createAsyncThunk('news/fetchNews', async () => {
  const response = await api.getTable('News');
  // Only return the `data` part of the response, which should be serializable
  return response.data;
});

// News slice
export const newsSlice = createSlice({
  name: 'news',
  initialState: initialNewsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNews.fulfilled, (state, action: PayloadAction<News[]>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchNews.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default newsSlice.reducer;
