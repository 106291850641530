import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const BottomBar: React.FC = () => {
  return (
    <Footer style={{ backgroundColor: '#ffff', padding: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={TextStyle}>Terms & Conditions</div>
      <div style={TextStyle}>© 2024 iTherapeut. All rights reserved.</div>
    </Footer>
  );
};

export default BottomBar;

const TextStyle = {
    fontWeight : '400',
    fontSize : '13px'
}