import React, { useState } from 'react';
import { Input, Button, Space, Form } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design stylesheet
import TextArea from 'antd/es/input/TextArea';

const FormNotes: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [form] = Form.useForm();

  interface FormNotesValues {
    notes?: string;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };


  const onFinish = (values: FormNotesValues) => {
    console.log('Received values of form: ', values);

  };

  const handleCancel = () => {
    form.resetFields(); // Reset form fields
  };


  return (
    <div style={borderStyle_Card}>
      <div style={{ maxWidth: '1323px', height: '285px', padding: '32px' }}>
        <div style={headingStyle}>Notes</div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{ marginBottom: 'auto' }}
        >
          <Form.Item
            name='notes'>
            <TextArea

              placeholder="Enter something"
              value={inputValue}
              onChange={handleInputChange}
              style={{ maxWidth: '1323px', height: '141px', marginBottom: '32px' }}
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ margin: '0 8px' }}>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            <div>
              <Button type="primary" htmlType="submit">
                Save changes
              </Button>
            </div>

          </div>
        </Form>
      </div>
    </div>
  );
};

export default FormNotes;

const borderStyle_Card = {
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  maxWidth: '1387px', height: '349px',
};

const headingStyle = {
  color: "var(--colorTextHeading, rgba(0, 0, 0, 0.88))",
  fontFamily: 'SF Pro Text',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
  flex: '1 0 0',
  marginBottom: '32px'
};