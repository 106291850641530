import React from 'react';
import { Form, Input, Button, Select, Radio } from 'antd';
const { Option } = Select;

interface FormValues {
    company?: string;
    address?: string;
    zipCode?: string;
    city?: string;
    email?: string;
    mobile?: string;
    phone?: string;
}

const UserFile: React.FC = () => {
    const [form] = Form.useForm();

    const [value, setValue] = React.useState<string | number | boolean>();

    const onChangeRadioButton = (e: any) => {

    };

    const onFinish = (values: FormValues) => {
        console.log('Received values of form: ', values);
    };

    return (
        <>
            <div style={borderStyle}>
                <div style={{ padding: '32px' }}>
                    <div style={styles_EditContact}>Edit Contact</div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        style={{ marginBottom: 'auto' }}
                    >
                        <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                            <Form.Item
                                name="firstName"
                                label="First Name"
                                style={{ flex: 1 }}
                                required
                            >
                                <Input placeholder="Name" />
                            </Form.Item>

                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                style={{ flex: 1 }}
                                required
                            >
                                <Input placeholder="lastName" />
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                            <Form.Item
                                name="gln"
                                label="GLN"
                                style={{ flex: 1 }}
                                required
                            >
                                <Input placeholder="GLN" />
                            </Form.Item>

                            <Form.Item
                                name="zsr"
                                label="ZSR"
                                style={{ flex: 1 }}
                                required
                            >
                                <Input placeholder="ZSR" />
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                style={{ flex: 1 }}
                                rules={[
                                    { type: 'email', message: 'The input is not valid E-mail!' },
                                ]}
                            >
                                <Input placeholder="email@email.com" />
                            </Form.Item>


                            <Form.Item
                                name="phone"
                                label="Phone"
                                style={{ flex: 1 }}
                            >
                                <Input addonBefore={<Select defaultValue="+XX">
                                    <Option value="+XX">+XX</Option>
                                    {/* Additional country codes can be added here */}
                                </Select>} placeholder="Phone Number" />
                            </Form.Item>


                        </div>

                        <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                            <Form.Item
                                name="checkTypeOfUser"
                                label="Type of User"
                                style={{ flex: 1 }}

                            >
                                <Radio.Group onChange={onChangeRadioButton} value={value} style={{ display: 'flex' }}>
                                    <Radio value="provider">Provider</Radio>
                                    <div style={{ width: '21px' }} /> {/* Adding 21px gap */}
                                    <Radio value="consumer">Consumer</Radio>
                                    <div style={{ width: '21px' }} /> {/* Adding 21px gap */}
                                    <Radio value="both">Both</Radio>
                                </Radio.Group>


                            </Form.Item>

                            <Form.Item
                                name="checkUserStatus"
                                label="User Status"
                                style={{ flex: 1 }}

                            >
                                <Radio.Group onChange={onChangeRadioButton} value={value} style={{ display: 'flex' }}>
                                    <Radio value="provider">Active</Radio>
                                    <div style={{ width: '21px' }} /> {/* Adding 21px gap */}
                                    <Radio value="consumer">Passive</Radio>
                                </Radio.Group>

                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <Button type="primary">
                                    Save changes
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div style={borderStyle_02}>
                <div style={{ padding: '32px' }}>
                    <div style={styles_EditContact}>Clinics</div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        style={{ marginBottom: 'auto' }}
                    >
                        <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                           <div>

                           </div>
                        </div>

                        <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                     
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <Button type="primary">
                                    Save changes
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div style={borderStyle_03}>
                <div style={{ padding: '32px' }}>
                    <div style={styles_EditContact}>Send Invitation</div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        style={{ marginBottom: 'auto' }}
                    >
                            <div style={{ maxHeight: '83px', maxWidth: '482px'}}>
                            <Form.Item
                                name="centreID"
                                label="Centre ID"
                                style={{ flex: 1 }}
                                required
                            >
                                <Input placeholder="Centre ID" />
                            </Form.Item>

                        </div>

                        <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                     
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <Button type="primary">
                                    Save changes
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div style={borderStyle_03}>
                <div style={{ padding: '32px' }}>
                    <div style={styles_EditContact}>Reset Password</div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        style={{ marginBottom: 'auto' }}
                    >
                            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                            <Form.Item
                                name="newPassword"
                                label="New Password"
                                style={{ flex: 1 }}
                                required
                            >
                                <Input placeholder="Password" />
                            </Form.Item>

                            <Form.Item
                                name="reapeatPassword"
                                label="Repeat Password"
                                style={{ flex: 1 }}
                                required
                            >
                                <Input placeholder="Repeat Password" />
                            </Form.Item>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <Button type="primary">
                                    Save changes
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div style={borderStyle_03}>
                <div style={{ padding: '32px' }}>
                    <div style={styles_EditContact}>Delete Account</div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        style={{ marginBottom: 'auto' }}
                    >
                            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
                            <Form.Item
                                name="inputDel"
                                label="Please enter DELETE if you want to delete this account."
                                style={{ flex: 1 }}
                                required
                            >
                                <div style={{ maxHeight: '83px', maxWidth: '482px'}}>
                                <Input placeholder="inputDel" />
                                </div>
                                
                            </Form.Item>

                    
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <Button type="primary">
                                    Save changes
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default UserFile;

const borderStyle = {
    borderRadius: "6px",
    border: "1px solid rgba(0, 0, 0, 0.06)",
    maxWidth: '1386px', maxHeight: '648px'
};
const borderStyle_02 = {
    borderRadius: "6px",
    border: "1px solid rgba(0, 0, 0, 0.06)",
    maxWidth: '1386px', maxHeight: '648px',
    marginTop: '32px'
};
const borderStyle_03 = {
    borderRadius: "6px",
    border: "1px solid rgba(0, 0, 0, 0.06)",
    maxWidth: '1386px', maxHeight: '296px',
    marginTop: '32px'
};

const styles_EditContact = {
    color: 'var(--colorTextHeading, rgba(0, 0, 0, 0.88))',
    fontFamily: 'SF Pro Text',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    paddingBottom: '32px'// This remains unchanged from the CSS
};




