import axios from 'axios';
import { requestInterceptor, responseInterceptor } from './interceptors';

export const publicCommonClient = axios.create({ baseURL: process.env.REACT_APP_COMMON_SERVICE_API_URL });
export const commonClient = axios.create({ baseURL: process.env.REACT_APP_COMMON_SERVICE_API_URL });

commonClient.interceptors.request.use(requestInterceptor);
commonClient.interceptors.response.use(undefined, responseInterceptor(commonClient));

export default {
  publicCommonClient,
  commonClient,
};
