import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { UserOutlined, TeamOutlined, MessageOutlined } from '@ant-design/icons';

import HeaderBarAccounts from './HeaderBarAccounts';
import FormDashboardMain from '../forms/FormDashboardMain';
import LoginBar from './LoginBar';
import BottomBar from './BootomBar'; // Assuming this is the correct import
import HeaderBarAccounts02 from './HeaderBarAccounts02';
import NewsPage from '../pages/NewsPage';

const { Content, Sider } = Layout;

const DashboardNavigation: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState<string>('1');
  const [isTableItemSelected, setIsTableItemSelected] = useState<boolean>(false);

  const onMenuClick = (e: { key: string }) => {
    setSelectedKey(e.key);
    setIsTableItemSelected(false); // Reset the table item selection
  };

  const menuItemStyle = {
    paddingRight: '43px', 
    width: '309px',
    overflow: 'hidden', 
  };

  const handleTableItemSelected = () => {
    setIsTableItemSelected(true); // Set to true when an item is selected
  };

  return (
    <Layout style={{ minHeight: '100vh', background: '#fff' }}>
      <Sider width={352} style={{ background: '#fff', height: '100vh' }}>
        
        <Menu defaultSelectedKeys={['1']} mode="inline" onClick={onMenuClick} style={{ background: '#fff', minHeight: '100vh'}}>
        <div style={{ padding: 16, marginLeft: 8, background: '#fff' }}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6baefde640c4cc006edeade5324d0c2abbb9c87286a96dbc1a995333d46210f7?apiKey=6da2ceac7c624ce0852d6e58c6b2f24d&"
            className="dashboard-logo"
            alt="Dashboard Logo"
            style={{ maxHeight: '64px' }}
          />
        </div>
          <Menu.Item key="1" icon={<UserOutlined />} style={menuItemStyle}>
            Accounts
          </Menu.Item>
          <Menu.Item key="2" icon={<TeamOutlined />} style={menuItemStyle}>
            Users
          </Menu.Item>
          <Menu.Item key="3" icon={<MessageOutlined />} style={menuItemStyle}>
            NewsPage
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ background: '#fff' }}>
      <LoginBar />
        <Content style={{ margin: '0 16px', background: '#fff' }}>
          <div style={{ padding: 24, minHeight: 360, background: '#fff' }}>
         
            {/*selectedKey === '1' && !isTableItemSelected && <FormDashboardMain onAccountSelect={handleTableItemSelected} /> */}
            {selectedKey === '1' && !isTableItemSelected && <FormDashboardMain onAccountSelect={handleTableItemSelected} />}
            {selectedKey === '1' && isTableItemSelected && <HeaderBarAccounts />}
            {selectedKey === '2' && <HeaderBarAccounts02 />}
            {selectedKey === '3' && <NewsPage></NewsPage>}
            {/* Add additional content for other keys as needed */}
          </div>
        </Content>
        <BottomBar />
      </Layout>
    </Layout>
  );
};

export default DashboardNavigation;

const menuItemStyle = {
  paddingRight: '43px', 
  width: '309px',
  overflow: 'hidden', 
};