import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';

import { fetchProviders } from '../features/providers/providersSlice';
import { fetchUsers } from '../features/providers/userSlice';

import { AppDispatch, RootState } from '../store';
import { ColumnsType } from 'antd/es/table';

interface DataType {
  key: string;
  id: string;
  name: string;
  email: string;
  clinics: string;
  gln: string;
  status: string;
}

const UserList: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();

  const users = useSelector((state: RootState) => state.users.data);
  const usersStatus = useSelector((state: RootState) => state.users.status);

  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
  }, [dispatch, usersStatus]);

  const mappedData: DataType[] = users.map(user => ({
    id: user?.user_id,
    key: user?.user_id,
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    clinics: '1',
    gln: '18312891',
    status: user?.active,
  }));

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = mappedData.filter(user =>
    user.name.toLowerCase().includes(searchText.toLowerCase()) ||
    user.email.toLowerCase().includes(searchText.toLowerCase()) ||
    user.gln.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Clinics',
      dataIndex: 'clinics',
      key: 'clinics',
    },
    {
      title: 'GLN',
      dataIndex: 'gln',
      key: 'gln',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: boolean) => (
        <Tag color={status ? 'blue' : 'orange'}>{status ? 'Active' : 'Inactive'}</Tag>
      ),
    },
  ];

  return (
    <div>
      <div style={headingStyle}>List</div>
      <Space style={{ marginBottom: 16 }}>
        <div style={{ width: '1323px', height: '43px', marginBottom: '32px' }}>
          <Search
            enterButton
            placeholder="Search by name, email, or GLN"
            onChange={handleSearch}
          />
        </div>
      </Space>
      <div style={{ width: '1323px' }}>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ position: ['bottomRight'] }}
        />
      </div>
    </div>
  );
};

export default UserList;

const headingStyle = {
  color: "var(--colorTextHeading, rgba(0, 0, 0, 0.88))",
  fontFamily: 'SF Pro Text',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
  flex: '1 0 0',
  marginBottom: '32px'
};
