import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import api from '../../interface/api/api';

interface User {
active: any;
firstName: any;
lastName: any;
email: any;
user_id: any;
smsActAtempts: any;
}
       
interface UsersState {
  data: User[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: UsersState = {
  data: [],
  status: 'idle',
};

// Async thunk action for fetching users
export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await api.getTable('User'); // Assuming this is the correct way to fetch users in your API
  // Only return the `data` part of the response, which should be serializable
  return response.data;
});

// Users slice
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Add any reducers for actions directly related to users (not async)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default usersSlice.reducer;
