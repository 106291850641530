import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Checkbox, Modal, Card } from 'antd';
import 'antd/dist/reset.css';
import { AppDispatch, RootState } from '../store';
import { signIn } from '../features/providers/authSlice';
import { useNavigate } from 'react-router-dom';
import { useProviderContext } from '../components/ProviderContext';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate(); 
  const { setUser } = useProviderContext(); 
  
  const auth = useSelector((state: RootState) => state.auth);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);

  const onFinish = async (values: { email: string; password: string }) => {
    const actionResult = await dispatch(signIn(values));
    if (signIn.fulfilled.match(actionResult)) { 
      const userData = actionResult.payload;
      if (userData && 'secretKey' in userData) {
        setUser(userData.user); 
        navigate('/twoFactorAuthPage', { state: { secretKey: userData.secretKey } });
      }
    } else if (signIn.rejected.match(actionResult)) {
      console.log('Login failed:', actionResult.payload);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const showForgotPasswordModal = () => {
    setIsForgotPasswordModalVisible(true);
  };

  const handleForgotPassword = (values: { forgotEmail: string }) => {
    console.log('Forgot Password Info:', values);
    setIsForgotPasswordModalVisible(false);
  };

  return (
    <div className="login-container" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#fff',
      }}>
      <Card title={<div style={{ textAlign: 'center', width: '100%' }}>Login</div>} bordered={false} style={{ maxWidth: 700, width: '100%', maxHeight: 500, height: '100%', backgroundColor: '#f6f6f6' }}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}
          >
            <Input placeholder="E-Mail" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginRight: 8, marginTop: '25px' }} loading={auth.isSigningIn}>
              Log in
            </Button>
            <a onClick={showForgotPasswordModal} style={{ marginLeft: 8, cursor: 'pointer' }}>
              Forgot password?
            </a>
          </Form.Item>

          {auth.signInError && <p style={{ color: 'red' }}>{auth.errorMessage}</p>}
        </Form>
      </Card>
      <Modal title="Forgot Password" visible={isForgotPasswordModalVisible} onCancel={() => setIsForgotPasswordModalVisible(false)} footer={null}>
        <Form name="forgotPassword" onFinish={handleForgotPassword}>
          <Form.Item name="forgotEmail" rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LoginPage;
