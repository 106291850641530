import React, { useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviders, updateTableEntry } from '../features/providers/providersSlice';
import { AppDispatch, RootState } from '../store';
import { useProviderContext } from '../components/ProviderContext';

const { Option } = Select;

interface FormAddressValues {
  company?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  email?: string;
  mobile?: string;
  phone?: string;
}

const FormAddress: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { selectedProviderId } = useProviderContext();

  const providersStatus = useSelector((state: RootState) => state.providers.status);
  const providers = useSelector((state: RootState) =>
    state.providers.data.find(prov => prov.centre_id === selectedProviderId)
  );

  useEffect(() => {
    if (providersStatus === 'idle') {
      dispatch(fetchProviders());
    }
  }, [dispatch, providersStatus]);

  useEffect(() => {
    if (providers) {
      form.setFieldsValue({
        company: providers?.name,
        address: `${providers?.Address?.street} ${providers?.Address?.building}`,
        zipCode: providers?.Address?.zipcode,
        email: providers?.email,
        city: providers?.Address?.city,
        mobile: providers?.countryPhone,
        phone: providers?.phone,
      });
    }
  }, [providers, form]);

  const onFinish = async (values: FormAddressValues) => {
    const addressParts = values?.address?.trim().split(/\s+/);
    const building = addressParts?.pop();
    const street = addressParts?.join(' ');

    const idForValues = providers?.centre_id;
    const idForAddress = providers?.Address?.address_id;

    await dispatch(updateTableEntry({ tableName: 'Centre', id: idForValues, data: { name: values.company, email: values.email, countryPhone: values.mobile, phone: values.phone } }));
    await dispatch(updateTableEntry({ tableName: 'Address', id: idForAddress, data: { street, building, zipcode: values.zipCode, city: values.city } }));

    // Re-fetch providers to update the state
    dispatch(fetchProviders());
  };

  const handleCancel = () => {
    form.resetFields();
  };

  return (
    <div style={{ borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.06)", maxWidth: '1386px', maxHeight: '451px' }}>
      <div style={{ padding: '32px' }}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{ marginBottom: 'auto' }}
        >
          <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
            <Form.Item<FormAddressValues>
              name="company"
              label="Company"
              style={{ flex: 1 }}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item<FormAddressValues>
              name="address"
              label="Address"
              style={{ flex: 1 }}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
            <Form.Item<FormAddressValues>
              name="zipCode"
              label="Zip Code"
              style={{ flex: 1 }}
            >
              <Input placeholder="Zip Code" />
            </Form.Item>

            <Form.Item<FormAddressValues>
              name="city"
              label="City"
              style={{ flex: 1 }}
            >
              <Input placeholder="City Name" />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
            <Form.Item<FormAddressValues>
              name="email"
              label="E-mail"
              style={{ flex: 1 }}
              rules={[
                { type: 'email', message: 'The input is not valid E-mail!' },
              ]}
            >
              <Input placeholder="email@email.com" />
            </Form.Item>

            <Form.Item<FormAddressValues>
              name="mobile"
              label="Mobile"
              style={{ flex: 1 }}
            >
              <Input addonBefore={<Select defaultValue="+XX"><Option value="+XX">+CH</Option></Select>} placeholder="Mobile Number" />
            </Form.Item>

            <Form.Item<FormAddressValues>
              name="phone"
              label="Phone"
              style={{ flex: 1 }}
            >
              <Input addonBefore={<Select defaultValue="+XX"><Option value="+XX">+CH</Option></Select>} placeholder="Phone Number" />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ margin: '0 8px' }}>
              <Button onClick={handleCancel}>Cancel</Button>
            </div>
            <div>
              <Button type="primary" htmlType="submit">Save changes</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FormAddress;
