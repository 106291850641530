import React, { createContext, useContext, useState, ReactNode } from 'react';

interface User {
  email: string;
  name: string;
}

interface ProviderContextType {
  selectedProviderId: string | null;
  setSelectedProviderId: (id: string | null) => void;
  user: User | null; // Add user to the context
  setUser: (user: User | null) => void; // Method to update the user
}

// Create a context with a default undefined value
const ProviderContext = createContext<ProviderContextType | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export const ProviderContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null); // State to hold the user data

  return (
    <ProviderContext.Provider value={{ selectedProviderId, setSelectedProviderId, user, setUser }}>
      {children}
    </ProviderContext.Provider>
  );
};

// Custom hook to use the context
export const useProviderContext = () => {
  const context = useContext(ProviderContext);
  if (context === undefined) {
    throw new Error('useProviderContext must be used within a ProviderContextProvider');
  }
  return context;
};
