import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../interface/api/api';

interface Provider {
  BusinessNumber: any;
  CentreProvider: any;
  Address: any;
  subscription_fk(subscription_fk: any): string;
  Subscription: any;
  centre_id: string;
  name: string;
  email: string;
  phone: string;
  countryPhone: string;
  User: any;
}

interface ProvidersState {
  data: Provider[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  subscriptionDetails: any;
}

const initialState: ProvidersState = {
  data: [],
  status: 'idle',
  error: null,
  subscriptionDetails: null,
};

export const fetchProviders = createAsyncThunk('providers/fetchProviders', async () => {
  const response = await api.getTable('Centre');
  return response.data;
});

export const fetchSubscriptionDetails = createAsyncThunk(
  'providers/fetchSubscriptionDetails',
  async (stripeCustomerId: string, { rejectWithValue }) => {
    try {
      const response = await fetch('http://localhost:5051/api/adminPanel/generateFormattedStripeSubList', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stripeCustomerId,
          mainSub: true,
          invoiceSub: true,
          smsSub: true,
        }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to fetch subscription details');
    }
  }
);

export const setInvoiceCredit = createAsyncThunk(
  'providers/setInvoiceCredit',
  async (SMSData: { subscriptionId: string; amount: number }, { rejectWithValue }) => {
    try {
      const response = await fetch('http://localhost:5051/api/adminPanel/invoiceCredit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(SMSData),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return { data, subscriptionId: SMSData.subscriptionId };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to set invoice credit');
    }
  }
);

export const setSMSCredit = createAsyncThunk(
  'providers/setSMSCredit',
  async (SMSData: { subscriptionId: string; amount: number }, { rejectWithValue }) => {
    try {
      const response = await fetch('http://localhost:5051/api/adminPanel/smsCredit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(SMSData),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return { data, subscriptionId: SMSData.subscriptionId };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to set SMS credit');
    }
  }
);

interface UpdateTableEntryParams {
  tableName: string;
  id: any;
  data: any;
}

export const updateTableEntry = createAsyncThunk(
  'providers/updateTableEntry',
  async ({ tableName, id, data }: UpdateTableEntryParams, { rejectWithValue }) => {
    try {
      const response = await fetch(`http://localhost:5051/api/adminPanel/table/${tableName}/id/${id}/updateTable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      });
      if (!response.ok) throw new Error('Failed to update table entry');
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to update table entry');
    }
  }
);

export const updateTrialEndDate = createAsyncThunk(
  'providers/updateTrialEndDate',
  async ({ subscriptionId, date }: { subscriptionId: string; date: any }, { rejectWithValue }) => {
    try {
      const response = await fetch('http://localhost:5051/api/adminPanel/trialEnd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscriptionId,
          date,
        }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Failed to update trial end date');
    }
  }
);

const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProviders.fulfilled, (state, action: PayloadAction<Provider[]>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch providers';
      })
      .addCase(fetchSubscriptionDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscriptionDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.subscriptionDetails = action.payload;
      })
      .addCase(fetchSubscriptionDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch subscription details';
      })
      .addCase(setInvoiceCredit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setInvoiceCredit.fulfilled, (state, action: PayloadAction<{ data: any; subscriptionId: string }>) => {
        state.status = 'succeeded';
        state.data = state.data.map(provider =>
          provider.Subscription.subscription_fk === action.payload.subscriptionId
            ? { ...provider, Subscription: { ...provider.Subscription, invoiceCredit: action.payload.data } }
            : provider
        );
      })
      .addCase(setInvoiceCredit.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to set invoice credit';
      })
      .addCase(setSMSCredit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setSMSCredit.fulfilled, (state, action: PayloadAction<{ data: any; subscriptionId: string }>) => {
        state.status = 'succeeded';
        state.data = state.data.map(provider =>
          provider.Subscription.subscription_fk === action.payload.subscriptionId
            ? { ...provider, Subscription: { ...provider.Subscription, smsCredit: action.payload.data } }
            : provider
        );
      })
      .addCase(setSMSCredit.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to set SMS credit';
      })
      .addCase(updateTableEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTableEntry.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateTableEntry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update table entry';
      })
      .addCase(updateTrialEndDate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTrialEndDate.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateTrialEndDate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update trial end date';
      });
  },
});

export default providersSlice.reducer;

