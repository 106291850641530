import React, { useState, useEffect } from 'react';
import { Input, Button, Card, Alert, Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { generateOTPToken } from '../features/providers/twoFactorAuthSlice';

const TwoFactorAuth: React.FC = () => {
  const [key, setKey] = useState<string>('');
  const [addCodeAlert, setAddCodeAlert] = useState<boolean>(false);
  const [otpToken, setOtpToken] = useState<any>('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const secretKey = location.state?.secretKey as string;

  useEffect(() => {
    if (!secretKey) {
      navigate('/login');
    } else {
      getToken();
    }
  }, [secretKey]);

  const getToken = async () => {
    const actionResult = await dispatch(generateOTPToken(secretKey));
    if (generateOTPToken.fulfilled.match(actionResult)) {
      setOtpToken(actionResult.payload);
    } 
  };

  const verifyCode = () => {
    if (key === otpToken.toString()) {
      navigate('/dashboard');
    } else {
      setAddCodeAlert(true);
    }
  };

  const handleKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      verifyCode();
    }
    getToken();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKey(event.target.value);
    getToken();
  };

  return (
    <Card title="Two Factor Verification">
      <Form>
        <Form.Item>
          <Input
            placeholder="Code"
            value={key}
            onChange={handleChange}
            onKeyPress={handleKeypress}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={verifyCode}>Verify</Button>
        </Form.Item>
      </Form>
      {addCodeAlert && <Alert message="The code is invalid" type="error" showIcon />}
    </Card>
  );
};

export default TwoFactorAuth;
