import { configureStore } from '@reduxjs/toolkit';
import providersReducer from './features/providers/providersSlice';
import subscriptionReducer from './features/providers/subcriptionsSlice';
import userSliceReducer from './features/providers/userSlice';
import newsReducer from './features/providers/newsSlice';
import authReducer from './features/providers/authSlice';

export const store = configureStore({
  reducer: {
    providers: providersReducer,
    subscriptions: subscriptionReducer,
    users: userSliceReducer,
    news: newsReducer,
    auth: authReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
