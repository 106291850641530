import React from 'react';
import { Layout, Space, Button, Modal } from 'antd';
import { MessageOutlined, BellOutlined, AppstoreOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';

const { confirm } = Modal;

const LoginBar: React.FC = () => {
  const showConfirm = (): void => {
    confirm({
      title: 'Are you sure you want to logout?',
      onOk() {
        console.log('Logout confirmed');
        // Add your logout logic here
      },
      onCancel() {
        console.log('Logout cancelled');
      },
    });
  };

  return (
    <div style={{ minHeight: '0px' }}>
      <div style={{ margin: '24px 24px 0px 24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MenuFoldOutlined style={{ marginRight: '16px' }} />
          <Search placeholder='Search Anything' style={{ marginRight: '16px', minWidth: '400px' }} />
        </div>
        <Space size="middle">
          <Button style={{ border: '1px solid #d9d9d9' }} icon={<MessageOutlined />} type="text" />
          <Button style={{ border: '1px solid #d9d9d9' }} icon={<AppstoreOutlined />} type="text" />
          <Button style={{ border: '1px solid #d9d9d9' }} icon={<BellOutlined />} type="text" />
        </Space>
      </div>
    </div>
  );
};

export default LoginBar;
