// features/subscriptions/subscriptionsSlice.tsx
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../interface/api/api';

interface Subscription {
  invoiceCountLeft: any;
  subscription_id: string;
  smsCountLeft: number;
  calendars : number;
  // Include other fields as necessary...
}

interface SubscriptionsState {
  data: Subscription[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: SubscriptionsState = {
  data: [],
  status: 'idle',
};

export const fetchSubscriptions = createAsyncThunk('subscriptions/fetchSubscriptions', async () => {
  const response = await api.getTable('Subscription'); // Adjust if your API call differs
  return response.data;
});

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action: PayloadAction<Subscription[]>) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default subscriptionsSlice.reducer;
