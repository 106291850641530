import React, { useState } from 'react';
import { Tabs } from 'antd';
import FormAddress from '../forms/FormAddress';
import FormInvoices from '../forms/FormInvoices';
import FormSubscription from '../forms/FormSubscription';
import FormSMS from '../forms/FormSMS';
import FormDashboardMain from '../forms/FormDashboardMain';
import FormUsers from '../forms/FormUsers';
import FormNotes from '../forms/FormNotes';
import UserFile from '../forms/UserFile';
import UserList from '../forms/UserList';

const { TabPane } = Tabs;

const HeaderBarAccounts02: React.FC = () => {
  // State to keep track of the currently active tab
  const [activeTab, setActiveTab] = useState<string>('1');

  // Callback function to update the active tab
  const callback = (key: string) => {
    console.log(key);
    setActiveTab(key); // Update the active tab state
  };


  // Function components for rendering forms
  const renderFormUser = () => <UserFile></UserFile>
  const renderUserList = () => <UserList></UserList>

  return (
    <>
      <div className="div" style={{ marginLeft: '10px' }}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="UserList" key="1">
            {activeTab === '1' && renderUserList()}
          </TabPane>
          <TabPane tab="User" key="2">
            {activeTab === '2' && renderFormUser()}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default HeaderBarAccounts02;
