import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Form, Input, Select, DatePicker, Space } from 'antd';
import moment from 'moment';
import { AppDispatch, RootState } from '../store';
import { fetchProviders, fetchSubscriptionDetails, updateTrialEndDate } from '../features/providers/providersSlice';
import { useProviderContext } from '../components/ProviderContext';

const { Option } = Select;

interface OrderHistoryRecord {
  key: string;
  date: string;
  frequency: string;
  added: number;
  remaining: number;
}

export interface FormSubscriptionValues {
  plan?: string;
  address?: string;
  discount?: string;
  duration?: string;
  trial?: string;
}

const orderHistoryData: OrderHistoryRecord[] = [
  {
    key: "2",
    date: "21.02.2025",
    frequency: "Yearly",
    added: 350,
    remaining: 794
  },
  {
    key: "11",
    date: "21.02.2034",
    frequency: "Yearly",
    added: 800,
    remaining: 6194
  },
  {
    key: "10",
    date: "21.02.2033",
    frequency: "Yearly",
    added: 750,
    remaining: 0
  },
  {
    key: "12",
    date: "21.02.2033",
    frequency: "Yearly",
    added: 750,
    remaining: 51394
  },
  {
    key: "13",
    date: "21.02.2033",
    frequency: "Yearly",
    added: 750,
    remaining: 0
  },
  {
    key: "14",
    date: "21.02.2033",
    frequency: "Yearly",
    added: 750,
    remaining: 0
  },
  {
    key: "10",
    date: "21.02.2033",
    frequency: "Yearly",
    added: 750,
    remaining: 5394
  },
];

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
  },
  {
    title: 'Added',
    dataIndex: 'added',
    key: 'added',
  },
  {
    title: 'Remaining Days',
    dataIndex: 'remaining',
    key: 'remaining',
    render: (remaining: number) => {
      const color = remaining < 1 ? 'red' : 'green';
      const dayText = remaining === 1 ? 'day' : 'days';

      return (
        <span style={{ color }}>
          {remaining} {dayText}
        </span>
      );
    },
  },
];

const FormSubscription: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { selectedProviderId } = useProviderContext();

  const providersStatus = useSelector((state: RootState) => state.providers.status);
  const subscriptionDetails = useSelector((state: RootState) => state.providers.subscriptionDetails);

  useEffect(() => {
    if (providersStatus === 'idle') {
      dispatch(fetchProviders());
    }
  }, [dispatch, providersStatus]);

  const providers = useSelector((state: RootState) =>
    state.providers.data.find(prov => prov.centre_id === selectedProviderId)
  );

  useEffect(() => {
    if (providers?.Subscription.stripeCustomerId) {
      dispatch(fetchSubscriptionDetails(providers.Subscription.stripeCustomerId));
    }
  }, [dispatch, providers?.Subscription.stripeCustomerId]);

  useEffect(() => {
    if (subscriptionDetails) {
      const mainSub = subscriptionDetails.mainSub.find((sub: { active: any; }) => sub.active);
      const interval = mainSub?.interval === 'month' ? 'Monthly' : mainSub?.interval === 'year' ? 'Yearly' : '';
      form.setFieldsValue({
        plan: interval,
        trial: mainSub?.active ? 'active' : 'inactive',
      });
    }
  }, [subscriptionDetails, form, providers?.Subscription?.trialExpiredAt]);

  const onFinish = (values: FormSubscriptionValues) => {
    console.log('Received values of form: ', values);
  };

  const handleCancel = () => {
    form.resetFields();
  };

  const handleDateChange = (date: any, dateString: string | string[]) => {
    if (Array.isArray(dateString)) {
      console.log('Selected trial start dates:', dateString.join(', '));
    } else {
      console.log('Selected trial start date:', dateString);
    }
  };

  const handleTrialEndDateChange = async (date: moment.Moment | null) => {
    console.log('Selected trial end date:', date);
    console.log('Updating trial end date:');

    const newTrialDate = date?.toDate();
    await dispatch(updateTrialEndDate({ subscriptionId: providers?.Subscription.subscription_id, date: newTrialDate }));
    dispatch(fetchProviders());
  };

  function formatSwissDate(dateInput: Date | undefined): string {
    if (!dateInput) {
      throw new Error("Invalid date input");
    }

    const date = new Date(dateInput);

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date input");
    }

    const now = new Date();
    const isExpired = date < now;

    const day = date.getDate();
    const month = date.toLocaleString('de-CH', { month: 'long' });
    const year = date.getFullYear();
    const formattedDate = `${day}. ${month} ${year}`;

    if (isExpired) {
      return `Expired at ${formattedDate}`;
    } else {
      return `Valid until ${formattedDate}`;
    }
  }

  return (
    <div style={borderStyle}>
      <div style={{ padding: '32px' }}>
        <div style={subscriptionHistoryLayout}>
          <div style={{ flex: 1, maxWidth: '629px', maxHeight: '587px' }}>
            <div style={headingStyle}>New Subscription</div>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item label="Plan" name="plan" style={{ marginBottom: '32px' }}>
                <Select placeholder="Select a plan">
                  <Option value="Monthly">Monthly</Option>
                  <Option value="Yearly">Yearly</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Address" name="address" style={{ marginBottom: '32px' }}>
                <Input placeholder="Enter your address" />
              </Form.Item>
              <Form.Item label="Discount" name="discount" style={{ marginBottom: '32px' }}>
                <Input placeholder="Enter discount code" />
              </Form.Item>
              <Form.Item label="Duration" name="duration" style={{ marginBottom: '32px' }}>
                <Input placeholder="Enter duration in months" />
              </Form.Item>
              <Form.Item label="Trial" name="trial" style={{ marginBottom: '32px' }}>
                <Select placeholder="Select a trial status">
                  <Option value="inactive">Inactive</Option>
                  <Option value="active">Active</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Extend Trial" name="setTrial" style={{ marginBottom: '32px' }}>
                <Space>
                  <div style={rectangleStyle}>
                    {formatSwissDate(providers?.Subscription?.trialExpiredAt)}
                  </div>
                  <div>Set new expire date</div>
                  <DatePicker
                    onChange={handleTrialEndDateChange}
                    placeholder="Select date"
                  />
                </Space>
              </Form.Item>
            </Form>
          </div>
          <div style={{ flex: 1, maxWidth: '629px', maxHeight: '587px' }}>
            <div style={{ maxWidth: '629px', height: '517px' }}>
              <div style={headingStyle}>Order History</div>
              <Table dataSource={orderHistoryData} columns={columns} pagination={{ pageSize: 5 }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ margin: '0 8px' }}>
                <Button onClick={handleCancel}>Cancel</Button>
              </div>
              <div>
                <Button type="primary" htmlType="submit">Save changes</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSubscription;

const borderStyle = {
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  maxWidth: '1386px',
  maxHeight: '725px'
};

const subscriptionHistoryLayout = {
  display: 'flex',
  gap: '64px',
  alignSelf: 'stretch',
};

const headingStyle = {
  color: 'var(--colorTextHeading, rgba(0, 0, 0, 0.88))',
  fontFamily: 'SF Pro Text',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '28px',
  flex: '1 0 0',
  marginBottom: '32px'
};

const rectangleStyle = {
  width: '200px',
  height: '30px',
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'rgba(0, 0, 0, 1)',
  fontFamily: 'SF Pro Text',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  marginRight: '16px' 
};
